import React from "react"

import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"
import ContactComponent from "../components/Contact/Contact"

const Contact = () => (
	<Layout>
		<SEO title="Contact Us" />
		<ContactComponent/>
	</Layout>
)

export default Contact;
